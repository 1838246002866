import { SnackbarComponent } from './../components/snackbar/snackbar.component';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  durationInSeconds = 3;

  constructor(private _snackBar: MatSnackBar) { }

  openSnackBar(text) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: this.durationInSeconds * 1000,
      data: {
        message: text
      }
    });
  }

  openSnackBarByBool(success, text?) {
    console.log("qui")
    if (success) {
      this.openSnackBar(text || "copiato");
    } else {
      this.openSnackBar("Errore nella copia");
    }
  }
}
