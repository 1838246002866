<div class="container mt-5 text-center">
  <div>
    <h1 class="presentation-name">Stefano Pasquale</h1>
    <p class="tagline">Programmatore, Sviluppatore </p>
  </div>
  <div class="img-holder mt-3">
    <div style="border-radius: 50%; background-color: #0e50c3;     width: 250px; margin: 0 auto;">
      <img src="assets/images/ste3.png" alt="phone" class="img-fluid" style="height: 250px;     border-radius: 50%;">
    </div>
  </div>
  <div class="quote-container">
    <app-quote class="quote" text="If you wanna change the world, start off by making your bed" ref="William H. McRaven"></app-quote>
  </div>
  <app-social></app-social>
  <!-- <div class="history">
    <app-history image="assets/images/Venaria_Reale-Stemma.png" text="Nato a Venaria Reale (TO) nel 1993"></app-history>
    <app-history image="assets/images/grassi.png" text="Diplomato in informatica al Carlo Grassi (TO), Progetto ABACUS"></app-history>
    <app-history image="assets/images/di-unito2.png" text="Laureato in informatica all' Università degli Studi di Torino"></app-history>
  </div> -->
  <app-skills></app-skills>
</div>
