import { ClipboardService } from './../../services/clipboard.service';
import { EMAIL, TELEGRAM } from './../../../utils/constants';
import { FACEBOOK, INSTAGRAM, GOOGLE, LINKEDIN } from '../../../utils/constants';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss']
})
export class CustomIconComponent implements OnInit {

  @Input() name: string;
  @Input() link?: string;
  @Input() mail?: string;

  facebook: string = FACEBOOK
  instagram: string = INSTAGRAM
  google: string = GOOGLE
  linkedin: string = LINKEDIN
  email: string = EMAIL
  telegram: string = TELEGRAM

  constructor(private _clipboardService: ClipboardService) { }

  ngOnInit(): void {
  }

  goToLink(e) {
    if (!!this.link) {
      window.open(this.link, '_blank');
    }
  }

  mailto(e) {
    if (!!this.mail) {
      this._clipboardService.copyToClipboard(this.mail);
      window.location.href = "mailto:"+this.mail;  
    }
  }

}
