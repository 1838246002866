import { TimeService } from './../../services/time.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  hi = "";
  constructor(private _timeService: TimeService) {
    this._timeService.buongiornissimoSubject.asObservable().subscribe((msg)=>{
      this.hi = msg;
    })
   }

  ngOnInit(): void {
  }

}
