<!-- Footer -->
<footer class="footer ">
  <!-- Footer Elements -->
  <div class="w-100">

    <div class="row">

      <div class="col-md-12  flex-center text-center w-100">
        <!-- <div class="flex flex-center">

          <app-websites></app-websites>
        </div> -->
        <!-- Copyright -->
        <div class="footer-copyright text-center py-3">© 2020
        </div>
        <!-- Copyright -->
      </div>
      

    </div>
    

  </div>
  <!-- Footer Elements -->
  
     
</footer>
<!-- Footer -->