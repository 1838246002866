<div class="socials">
  <!-- <a class="social">
    <app-custom-icon name="facebook" link="https://www.facebook.com/stefano.pasquale.5/"></app-custom-icon>
  </a> -->
  <a class="social">
    <app-custom-icon name="linkedin" link="https://www.linkedin.com/in/stefano-pasquale-94b8a4159/"></app-custom-icon>
  </a>
  <!-- <a class="social">
    <app-custom-icon name="email" mail="stepasq93@gmail.com"></app-custom-icon>
  </a>
  <a class="social">
    <app-custom-icon name="instagram" link="https://www.instagram.com/th_ri_ve/"></app-custom-icon>
  </a>
  <a class="social">
    <app-custom-icon name="telegram" link="https://t.me/StefanoPasquale"></app-custom-icon>
  </a> -->
</div>
