import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  now = Date.now();
  data = [
    [0, 'Buona notte'],
    [5, 'Buon giorno'],
    [12, 'Buon pomeriggio'],
    [18, 'Buona sera'],
  ];

  buongiornissimoSubject: Subject<any> = new Subject ;

  constructor() {
    setInterval(() => {
      this.now = Date.now();
      this.buongiornissimo();
    }, 1);
  }

  buongiornissimo() {
    var hr = new Date(this.now).getHours();
    var hi = 0 + '';
    this.data.forEach((hour) => {
      if (hr >= hour[0]) {
        hi = hour[1] + '';
      }
    });
    this.buongiornissimoSubject.next(hi);
  }

}
