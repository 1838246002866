<div class="history-container">
  <div class="history">
    <div class="history-image" >
      <img class="image" [src]="image"/>
    </div>
    <div class="history-text">{{ text }}</div>
  </div>
</div>


