import { SnackbarService } from './snackbar.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor(private _snackbarService: SnackbarService) { }

  copyToClipboard(text: string){
    var textArea = document.createElement("textarea");

    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
  
    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';
  
    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0';
  
    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
  
    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';
  
  
    textArea.value = text;
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    textArea.setSelectionRange(0, 99999); /*For mobile devices*/
  
    try {
      var successful = document.execCommand('copy');

      this._snackbarService.openSnackBarByBool(successful, text);
    } catch (err) {
      this._snackbarService.openSnackBarByBool(false);

    }
  
    document.body.removeChild(textArea);
  }
}
