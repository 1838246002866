import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
// modules
import { MaterialModule } from './modules/material/material.module';
// components
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { MainComponent } from './pages/main/main.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { FooterComponent } from './components/footer/footer.component';
import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { PresentationSectionComponent } from './components/presentation-section/presentation-section.component';
import { TagComponent } from './components/tag/tag.component';
import { WebsitesComponent } from './components/websites/websites.component';
import { HistoryComponent } from './components/history/history.component';
import { SocialComponent } from './components/social/social.component';
import { QuoteComponent } from './components/quote/quote.component';
import { SkillsComponent } from './components/skills/skills.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ToolbarComponent,
    MainComponent,
    PageNotFoundComponent,
    FooterComponent,
    CustomIconComponent,
    PresentationSectionComponent,
    TagComponent,
    WebsitesComponent,
    HistoryComponent,
    SocialComponent,
    QuoteComponent,
    SkillsComponent,
    SnackbarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
